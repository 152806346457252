import { combineReducers } from "redux";
import user from "./user";
import myShop from "./myShop";
import categories from "./categories";
import shops from "./shops";
import products from "./products";
import appReducer from "./appReducer";
import wishlist from "./wishlist";
import cart from "./cart";
import orders from "./orders";
import users from "./users";
import banners from "./banners";
const rootReducer = combineReducers({
  user,
  myShop,
  categories,
  shops,
  products,
  appReducer,
  wishlist,
  cart,
  orders,
  users,
  banners,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
