import axios from "axios";
import { app } from "../constants";
import { ICategory, IProduct, Ishop } from "../interfaces";
import { errorHandler, setHeaders } from "../helpers";

export const SET_USERS = "SET_USERS";
export const SET_IS_LOADING_USERS = "SET_IS_LOADING_USERS";
export const RESET_USERS = "RESET_USERS";

interface IAction {
  type: string;
  payload: any;
}
export const setUsers = (list: IProduct[]): IAction => ({
  type: SET_USERS,
  payload: list,
});
export const setIsLoadingUsers = (value: boolean): IAction => ({
  type: SET_IS_LOADING_USERS,
  payload: value,
});

export const resetUsers = () => ({ type: RESET_USERS });

export const fetchUsers = (): any => (dispatch: any, getState: any) => {
  const { user } = getState();
  dispatch(setIsLoadingUsers(true));
  axios
    .get(app.BACKEND_URL + "/users", setHeaders(user.token))
    .then((res) => {
      dispatch(setUsers(res.data.clients));
      dispatch(setIsLoadingUsers(false));
    })
    .catch((error) => {
      errorHandler(error);
      dispatch(setIsLoadingUsers(false));
    });
};
