import axios from "axios";
import { app } from "../constants";
import { IOrder } from "../interfaces";
import { errorHandler, setHeaders } from "../helpers";

export const SET_CLIENT_ORDERS = "SET_CLIENT_ORDERS";
export const SET_ADMIN_ORDERS = "SET_ADMIN_ORDERS";
export const SET_IS_LOADING_ORDERS = "SET_IS_LOADING_ORDERS";
export const RESET_ORDERS = "RESET_ORDERS";

interface IAction {
  type: string;
  payload: any;
}
export const setClienctOrders = (orders: IOrder[]): IAction => ({
  type: SET_CLIENT_ORDERS,
  payload: orders,
});
export const setAdminOrders = (orders: IOrder[]): IAction => ({
  type: SET_ADMIN_ORDERS,
  payload: orders,
});
export const setIsLoadingOrders = (value: boolean): IAction => ({
  type: SET_IS_LOADING_ORDERS,
  payload: value,
});

export const resetOrders = () => ({ type: RESET_ORDERS });

export const fetchClientsOrders = (): any => (dispatch: any, getState: any) => {
  const { user } = getState();
  dispatch(setIsLoadingOrders(true));
  axios
    .get(app.BACKEND_URL + "/orders", setHeaders(user.token))
    .then((res) => {
      dispatch(setClienctOrders(res.data.orders));
      dispatch(setIsLoadingOrders(false));
    })
    .catch((error) => {
      errorHandler(error);
      dispatch(setIsLoadingOrders(false));
    });
};

export const fetchAdminsOrders = (): any => (dispatch: any, getState: any) => {
  const { user } = getState();
  dispatch(setIsLoadingOrders(true));
  axios
    .get(app.BACKEND_URL + "/orders/all", setHeaders(user.token))
    .then((res) => {
      dispatch(setAdminOrders(res.data.orders));
      dispatch(setIsLoadingOrders(false));
    })
    .catch((error) => {
      errorHandler(error);
      dispatch(setIsLoadingOrders(false));
    });
};
