export const sellerNavigation = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: "bi bi-speedometer2",
  },
  {
    title: "My Shop Settings",
    href: "/dashboard/myshop",
    icon: "bi bi-gear",
  },
  {
    title: "My Wishlist",
    href: "/dashboard/wishlist",
    icon: "bi bi-layout-split",
  },
  {
    title: "Products",
    href: "/dashboard/products",
    icon: "bi bi-card-text",
  },
  {
    title: "Add New Product",
    href: "/dashboard/addproduct",
    icon: "bi bi-card-text",
  },
  {
    title: "My orders",
    href: "/dashboard/orders",
    icon: "bi bi-card-text",
  },
];
