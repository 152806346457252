import { IAction, IUser } from "../interfaces";
import { RESET_USERS, SET_IS_LOADING_USERS, SET_USERS } from "../actions/users";

interface IClient extends IUser {
  createdAt: string;
  isEmailVerified: boolean;
}
export interface IUsersReducer {
  isLoading: boolean;
  users: IClient[];
}

const initialState: IUsersReducer = {
  isLoading: false,
  users: [],
};

const wishlist = (
  state: IUsersReducer = initialState,
  action: IAction
): IUsersReducer => {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload };
    case SET_IS_LOADING_USERS:
      return { ...state, isLoading: action.payload };
    case RESET_USERS:
      return initialState;
    default:
      return state;
  }
};

export default wishlist;
