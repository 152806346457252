import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TOAST_MESSAGE_TYPES } from "../interfaces";
import { fetchCategories } from "../actions/categories";
import { fetchShops } from "../actions/shops";
import { fetchProducts } from "../actions/products";
import { app } from "../constants";
import { fetchBanners } from "../actions/banners";

//custom dispatcher hook
export const useLoadBasicData = (): any => {
  const dispatch = useDispatch();
  return (payload: any) => {
    dispatch(fetchCategories());
    dispatch(fetchShops());
    dispatch(fetchProducts());
    dispatch(fetchBanners());
  };
};

export const handleAuthError = (error: any) => {
  if (error?.response?.status === 401) {
    //@ts-ignore
    window.location =
      "/logout?redirect=" + window.location.pathname.replace("/", "");
    //@ts-ignore
  }
};

export const returnSlugOrId = (item: any, idAttribute: string) => {
  return item?.slug
    ? item.slug.trim().length > 0
      ? item.slug
      : item[idAttribute]
    : item[idAttribute];
};

export const returnErroMessage = (error: any) => {
  if (error?.response?.data?.msg) {
    return error.response.data.msg;
  } else if (error.message) {
    return error.message;
  } else {
    return error;
  }
};

export const openUrlInNewTab = (url: string, self: boolean = true) => {
  const urlToUse = self ? app.PUBLIC_URL + url : url;
  window.open(urlToUse, "_blank");
};

export const randomNumber = () => {
  const max = 99999;
  const min = 11111;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const toastMessage = (type: TOAST_MESSAGE_TYPES, message: string) => {
  if (type === TOAST_MESSAGE_TYPES.INFO) {
    toast.info(message);
  }
  if (type === TOAST_MESSAGE_TYPES.ERROR) {
    toast.error(message);
  }
  if (type === TOAST_MESSAGE_TYPES.SUCCESS) {
    toast.success(message);
  }
};

export const errorHandler = (error: any) => {
  if (error?.response?.data?.msg) {
    toastMessage(TOAST_MESSAGE_TYPES.ERROR, error.response.data.msg);
  } else {
    toastMessage(TOAST_MESSAGE_TYPES.ERROR, error.message);
  }
  handleAuthError(error);
};

export const setHeaders = (token: string) => {
  return {
    headers: {
      token: token,
    },
  };
};
export const currencyFormatter = (num: any): string => {
  if (
    isNaN(num) ||
    num === undefined ||
    num === null ||
    typeof num === "undefined"
  ) {
    // throw new Error(`currencyFormatter Failed,not a NUM`)
    // console.log("Num:-", num)
    return "";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(Number(num));
};
