import { IAction, IOrder } from "../interfaces";
import {
  RESET_ORDERS,
  SET_ADMIN_ORDERS,
  SET_CLIENT_ORDERS,
  SET_IS_LOADING_ORDERS,
} from "../actions/orders";

export interface IOrdersReducer {
  isLoading: boolean;
  clientsOrders: IOrder[];
  adminsOrders: IOrder[];
}
const initialState: IOrdersReducer = {
  isLoading: false,
  clientsOrders: [],
  adminsOrders: [],
};

const user = (
  state: IOrdersReducer = initialState,
  action: IAction
): IOrdersReducer => {
  switch (action.type) {
    case SET_CLIENT_ORDERS:
      return { ...state, clientsOrders: action.payload };
    case SET_ADMIN_ORDERS:
      return { ...state, adminsOrders: action.payload };
    case SET_IS_LOADING_ORDERS:
      return { ...state, isLoading: action.payload };
    case RESET_ORDERS:
      return initialState;
    default:
      return state;
  }
};

export default user;
